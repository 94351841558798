.avatar-block[data-v-0fa41229] {
  position: relative;
  padding: 12px;
  border-radius: 12px;
  cursor: pointer;
}
.avatar-block .close-product-box[data-v-0fa41229] {
  position: absolute;
  right: 2px;
  top: 2px;
  width: 18px;
  height: 18px;
}
.avatar-block .close-product-box img[data-v-0fa41229] {
  width: 18px;
  height: 18px;
}
.avatar-block .close-product-box .product-close[data-v-0fa41229] {
  display: none;
}
.avatar-block .close-product-box .product-close-red[data-v-0fa41229] {
  display: none;
}
.avatar-block[data-v-0fa41229]:hover {
  background-color: rgba(202, 209, 214, 0.2);
}
.avatar-block:hover .product-close[data-v-0fa41229] {
  display: inline-block;
}
.avatar-block:hover .close-product-box:hover .product-close[data-v-0fa41229] {
  display: none;
}
.avatar-block:hover .close-product-box:hover .product-close-red[data-v-0fa41229] {
  display: inline-block;
}
.el-avatar[data-v-0fa41229] {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
}
.el-avatar[data-v-0fa41229]  img {
  width: 100%;
  height: 100%;
}
.row[data-v-0fa41229] {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.name[data-v-0fa41229] {
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 18px;
  margin-bottom: 4px;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
.user-tag[data-v-0fa41229] {
  background-color: #2969E3;
  color: #FFFFFF;
  padding: 4px 8px;
  border-radius: 6px;
  margin-left: 4px;
  font-size: 12px;
}
.desc[data-v-0fa41229] {
  font-size: 14px;
  color: #a7aaac;
  text-align: left;
  white-space: nowrap;
}