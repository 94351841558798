.img-item[data-v-5249295b],
.upload[data-v-5249295b] {
  position: relative;
  margin: 0 8px 8px 0;
  width: 100px;
  height: 100px;
  border-radius: 12px;
  display: inline-block;
  vertical-align: middle;
}
.img-item .tag[data-v-5249295b],
.upload .tag[data-v-5249295b] {
  position: absolute;
  top: 0;
  left: 0;
  text-align: center;
  width: 40px;
  height: 18px;
  line-height: 18px;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 12px 0 6px 0;
  font-weight: 400;
  color: #fff;
  font-size: 10px;
}
.upload[data-v-5249295b] {
  border: 1px dashed #dcdee0;
  background: #f8f9fa;
  font-size: 28px;
  color: #646566;
  line-height: 100px;
  text-align: center;
}
.upload[data-v-5249295b]  .el-upload {
  width: 100%;
}
.upload.loading[data-v-5249295b] {
  line-height: 1;
  overflow: hidden;
}
.upload.loading[data-v-5249295b]  .el-loading-spinner {
  margin-top: -14px;
}
.upload.loading[data-v-5249295b]  .el-loading-spinner .circular {
  width: 28px;
  height: 28px;
}
.img-item[data-v-5249295b] {
  position: relative;
}
.img-item .el-image[data-v-5249295b] {
  width: inherit;
  height: inherit;
  border-radius: inherit;
}
.img-item:hover .img-remove[data-v-5249295b] {
  display: block;
}
.img-item .img-remove[data-v-5249295b] {
  position: absolute;
  top: -8px;
  right: -8px;
  font-size: 16px;
  color: #eaecef;
  cursor: pointer;
  display: none;
}
.tip[data-v-5249295b] {
  color: #a7aaaa;
  line-height: 1.5;
}