.head-bar[data-v-46da4451] {
  padding: 0 24px;
  height: 48px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background: #fff;
  -webkit-box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.12);
          box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.12);
  border-bottom: 1px solid #F6F6F6;
}
.head-bar[data-v-46da4451]  .el-breadcrumb__item:last-child .el-breadcrumb__inner {
  font-weight: 700;
}
.head-bar .el-dropdown-link[data-v-46da4451] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  cursor: pointer;
}
.head-bar .el-icon-arrow-down[data-v-46da4451] {
  color: #000;
  font-size: 10px;
  font-weight: bold;
  margin-left: 8px;
}
.user-block[data-v-46da4451] {
  margin: -12px;
  width: 240px;
  z-index: 100;
  position: relative;
}
.user-block .user-info[data-v-46da4451] {
  padding: 18px;
  background: #f2f7ff;
}
.user-block .operation[data-v-46da4451] {
  width: 100%;
}
.user-block .operation .el-button[data-v-46da4451] {
  padding-top: 20px;
  padding-bottom: 20px;
}
.avatar-cover[data-v-46da4451] {
  position: relative;
  cursor: pointer;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  overflow: hidden;
}
.avatar-cover.is-upload[data-v-46da4451]:after {
  display: none;
}
.avatar-cover[data-v-46da4451]:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAAAXNSR0IArs4c6QAAA0JJREFUaEPtmDloVFEUhr8j7mIlgoiNVoKNShoR3I0gJiJaKTaCjSDivm+IC2qaiDFYCDZKQAtNtFAsrBTRRrAThAhiI264L0cOnieXybzJvC3jwHsQCDPvvvt//3/PveeN0OSXNLl+SoBGJ1gmUCaQ0YFCl5CqjgDeAuOA4SLyK6PeAcMLA3Dx3cAGn3U9cDVviEIAVHUMsB/YCYx0AEviAHBRRH7mlUTuAC5+n4sfVSH0HXAQ6M4LIleAQPwOYHSMywZxCLiQB0RuAC5+rzsfJz5ieg8cBrpE5EeW5ZQLgKqaYBO/K3D+M2BuT3aBL4ApgO1Mdn1wiPNZIDIDuPg9Lt6K1y4TfwpYAszzz24Cz4HNFRBHgXNpITIBuPjdgP2F4k8CncANYEEAsBY4AmwJID4CBtGZBiI1QIz4L8AJ4IwdXMAtYH4EICIrVdUONSvirRUQx4CzIqJJaiIVgB9SttPYlhg5b+LN+dMi8s2F9oUJGICJ8+9srEFE58QnGy8ix4cCYCpwHZjlk30NxNv/kciqAP79WDdgWwDxCFglIq/qhUibwCTgMtAKmGBrGfaY89HEVRLoFZH2UJiqGoSd2JaEJfnAllySWkgF4A5O87V8D7hS2ePUA+DPMeFW6DOAHhF5Wa/7dl9qgMEmcXdtCS30ewckEKQlSYs3GjuUAH0i0jYYeNLvawKoqvXwqTrHKglYGu1pnY7TEgugqpuAFcAzaxOSgjhAL7DIXbUzoS0pgKoOsw3Cz5O7QEf4jKoAqjoTuANM9LZguYjcTxKvN3fmegSQKgFVNRN7ANuxXgPLRORpzRpQ1dnAk0DwGhGxfb/uqwpA2gRWA9eCiVtE5J+2uARKAE/AamBxUANWxL/rjvHvid7QBJoewNrppe74bd+FmiYBa6e7gI22EnwnWdc0S8j7nPHeL/UDl0TEWuZEV8NqII8+x01oTBEnsrnGzQ1PICtIXgAd/rKRVU+a8XOA7VlP4jQTFzWmrlbC3nUfBu+qRYlJ+tzvwFwReTxYMzcBsB+iWv4jCBNvwlvD7bjW+4D9fjPdD6GkThVxvx2G/SLyJnx4Ya+URRBUe2YJMFROx81TJlAmkNGBpl9CfwCYvJBA8C5m7QAAAABJRU5ErkJggg==);
  background-repeat: no-repeat;
  background-size: 24px;
  background-position: 50%;
}
.avatar-cover img[data-v-46da4451] {
  width: 100%;
  height: 100%;
}