.nest-menu .level1[data-v-69412eda] {
  padding: 0;
}
.level1[data-v-69412eda] {
  padding: 0 10px;
}
.level1 .el-menu-item[data-v-69412eda] {
  width: auto !important;
}
.level1 .el-menu-item.is-active[data-v-69412eda] {
  background-color: #e4e5ea !important;
}