.app-wrapper[data-v-798c616b] {
  position: relative;
  width: 100%;
  height: 100%;
}
.app-wrapper.hideSidebar .headbar-container[data-v-798c616b] {
  left: 54px;
}
.app-wrapper .sidebar-container[data-v-798c616b] {
  padding-bottom: 40px;
}
.app-wrapper .headbar-container[data-v-798c616b] {
  position: fixed;
  top: 0;
  left: 188px;
  right: 0;
  z-index: 99;
  -webkit-transition: left 0.28s;
  transition: left 0.28s;
}
.app-wrapper .main-container[data-v-798c616b] {
  padding-top: 48px;
  background: #fff;
}
.app-wrapper .main-container[data-v-798c616b]  .full-page {
  height: calc(100vh - 48px);
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}