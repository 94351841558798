[data-v-8668286a] .el-upload {
  width: 100%;
}
[data-v-8668286a] .el-upload:focus {
  color: inherit;
  border-color: inherit;
}
[data-v-8668286a] .el-upload-dragger {
  height: 220px;
  width: 100%;
  background: #f8f9fb;
  border-radius: 12px;
  border: 1px solid #e7eef8;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
[data-v-8668286a] .el-upload:focus .el-upload-dragger {
  border-color: #e7eef8;
}
[data-v-8668286a] .list .el-upload-dragger {
  height: 180px;
  border-bottom: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
[data-v-8668286a] .el-upload__tip {
  margin-top: 10px;
  font-size: 14px;
  color: #a7aaac;
}
[data-v-8668286a] .el-upload__text {
  margin-top: 15px;
  color: #6e777f;
}
[data-v-8668286a] .list .el-upload-list {
  height: auto;
  border-width: 1px;
  padding: 8px 10px;
  height: 40px;
  line-height: 40px;
}
[data-v-8668286a] .el-upload-list {
  height: 0;
  background: #f8f9fb;
  border: 0 solid #e7eef8;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
[data-v-8668286a] .el-upload-list__item:first-child {
  margin-top: 0;
}
.upload-text .icon[data-v-8668286a] {
  color: #2969e3;
  height: 23px;
}
.upload-text .text[data-v-8668286a] {
  margin-left: 10px;
  font-size: 20px;
  color: #000;
}