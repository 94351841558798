.filter-bar[data-v-8d1507a2] {
  margin-bottom: 15px;
}
.page-bar[data-v-8d1507a2] {
  margin-top: 15px;
}
.page-bar .page-right[data-v-8d1507a2] {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}
.page-bar .page-right .page[data-v-8d1507a2] {
  margin-left: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.page-bar .page-right .total[data-v-8d1507a2] {
  font-size: 13px;
}