.text[data-v-58be14da] {
  display: inline-block;
}
.text .dot[data-v-58be14da] {
  margin-right: 5px;
  display: inline-block;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #46bc95;
  vertical-align: middle;
}
.text.light[data-v-58be14da] {
  color: #6e777f;
}
.text.light .dot[data-v-58be14da] {
  background: #bec9d2;
}