.sub-el-icon[data-v-54d76058] {
  color: currentColor;
  width: 1em;
  height: 1em;
}
.menu-icon[data-v-54d76058] {
  font-size: 1em !important;
  width: 1em !important;
  height: 1em !important;
}
.menu-icon.menu-subitem[data-v-54d76058] {
  width: 0.8em !important;
  height: 0.8em !important;
}