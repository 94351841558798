.el-pagination[data-v-6cfde6b5]  span.el-pagination__sizes {
  margin: 0 10px;
  width: 116px;
  height: 32px;
  line-height: 32px;
  background: #f8f9fa;
  -webkit-box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.1);
          box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  border: 1px solid #f5f5f5;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.el-pagination[data-v-6cfde6b5]  span.el-pagination__sizes input {
  background: transparent;
  border: 0;
}
.el-pagination[data-v-6cfde6b5]  span.el-pagination__sizes .el-select__caret {
  color: #6e777f;
}
.el-pagination[data-v-6cfde6b5]  span.el-pagination__jump {
  margin: 0 10px;
  width: 137px;
  height: 32px;
  line-height: 32px;
  background: #f8f9fa;
  -webkit-box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.1);
          box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.1);
  border-radius: 12px 12px 12px 12px;
  text-align: center;
  border: 1px solid #f5f5f5;
}
.el-pagination[data-v-6cfde6b5]  span.el-pagination__jump .el-input {
  width: 60px;
  padding: 0 10px;
}
.el-pagination[data-v-6cfde6b5]  span.el-pagination__jump .el-input input.el-input__inner {
  height: 32px;
  border-radius: 0;
  border-top: 0;
  border-bottom: 0;
  border-color: #eff0f2;
  background: transparent;
}
.el-pagination[data-v-6cfde6b5]  .btn-prev {
  margin: 0;
  margin-left: 10px;
  height: 32px;
  border-radius: 12px 0 0 12px;
  background-color: #f5f5f5;
  -webkit-box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.1);
          box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.1);
}
.el-pagination[data-v-6cfde6b5]  .el-pager {
  background-color: #f5f5f5;
  -webkit-box-shadow: 3px 1px 2px 1px rgba(0, 0, 0, 0.1);
          box-shadow: 3px 1px 2px 1px rgba(0, 0, 0, 0.1);
}
.el-pagination[data-v-6cfde6b5]  .el-pager .number {
  margin: 0;
  min-width: 26px;
  height: 32px;
  line-height: 32px;
  border-radius: 0;
  background-color: #f5f5f5;
}
.el-pagination[data-v-6cfde6b5]  .btn-next {
  margin: 0;
  height: 32px;
  border-radius: 0 12px 12px 0;
  background-color: #f5f5f5;
  -webkit-box-shadow: 3px 1px 3px 1px rgba(0, 0, 0, 0.1);
          box-shadow: 3px 1px 3px 1px rgba(0, 0, 0, 0.1);
}