.table-page[data-v-b52ec1ce] {
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  position: relative;
  overflow: hidden;
}
.table-page-channel[data-v-b52ec1ce] {
  height: calc(100vh - 128px) !important;
}
.search-container[data-v-b52ec1ce] {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  padding: 10px 24px;
  width: 100%;
  background: #fff;
  -webkit-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.12);
          box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.12);
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-transition: height 0.25s;
  transition: height 0.25s;
  overflow: hidden;
  position: relative;
  z-index: 100;
}
.search-container .el-form[data-v-b52ec1ce] {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
.table-container[data-v-b52ec1ce] {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  padding: 32px 24px;
  padding-bottom: 64px;
  overflow: auto;
}
.footer-container[data-v-b52ec1ce] {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  background: #fff;
  z-index: 99;
  -webkit-box-shadow: 10px -2px 20px 0 rgba(0, 0, 0, 0.06);
          box-shadow: 10px -2px 20px 0 rgba(0, 0, 0, 0.06);
  padding: 10px 20px;
}
.toggle-button[data-v-b52ec1ce] {
  margin-top: 40px;
  margin-left: 5px;
}
.search-container[data-v-b52ec1ce]  .el-form-item__label {
  color: #6e777f;
  font-weight: normal;
}
.search-container[data-v-b52ec1ce]  .el-form--label-top .el-form-item__label {
  padding: 0;
}
.search-container[data-v-b52ec1ce]  .el-form-item {
  margin-right: 24px;
  margin-bottom: 10px;
}
.openSidebar .footer[data-v-b52ec1ce] {
  left: 188px;
}