.footer[data-v-39f58aa4] {
  margin-top: 30px;
}
.el-input[data-v-39f58aa4] {
  height: 40px;
}
[data-v-39f58aa4] input,[data-v-39f58aa4] textarea {
  background: #f8f9fa;
  border-color: #f5f5f5;
  border-radius: 12px;
  -webkit-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
          box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
}
[data-v-39f58aa4] input:hover,[data-v-39f58aa4] input:focus,[data-v-39f58aa4] textarea:hover,[data-v-39f58aa4] textarea:focus {
  border-color: #ccc;
}
[data-v-39f58aa4] .el-input__count {
  background: transparent;
  color: #6e777f;
}
[data-v-39f58aa4] .el-input__count .el-input__count-inner {
  background: transparent;
}
[data-v-39f58aa4] textarea + .el-input__count {
  line-height: 2;
}