.edit-page[data-v-0bb3e5cd] {
  position: relative;
}
.edit-page .full[data-v-0bb3e5cd] {
  height: 100%;
  overflow: hidden;
}
.edit-page .bottom[data-v-0bb3e5cd] {
  padding-bottom: 64px;
}
.edit-page .footer[data-v-0bb3e5cd] {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 10px 20px;
  background: #fff;
  -webkit-box-shadow: 10px -2px 20px 0px rgba(0, 0, 0, 0.06);
          box-shadow: 10px -2px 20px 0px rgba(0, 0, 0, 0.06);
  z-index: 99;
}
.edit-page .footer .el-button + .el-button[data-v-0bb3e5cd] {
  margin-left: 24px;
}