.el-date-editor[data-v-5b93a7f7] {
  width: 344px;
  height: 40px;
  background: #f8f9fa;
  border-color: #f5f5f5;
  border-radius: 12px;
  -webkit-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
          box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
}
.el-date-editor[data-v-5b93a7f7]:hover, .el-date-editor.is-active[data-v-5b93a7f7] {
  border-color: #ccc;
}
.el-date-editor[data-v-5b93a7f7]  input {
  background: transparent;
}
.el-date-editor--date[data-v-5b93a7f7] {
  border: 1px;
  overflow: hidden;
}
.el-date-editor--date[data-v-5b93a7f7]  input {
  border-radius: inherit;
  border-color: #f5f5f5;
}
.el-date-editor--date[data-v-5b93a7f7]  input:hover, .el-date-editor--date[data-v-5b93a7f7]  input:focus-visible, .el-date-editor--date[data-v-5b93a7f7]  input.is-active {
  border-color: #ccc;
}