[data-v-193f5ee3] .el-dialog {
  border-radius: 16px;
}
[data-v-193f5ee3] .el-dialog__header {
  border-bottom: 1px solid #eff0f2;
  font-weight: 18px;
  font-weight: 500;
}
[data-v-193f5ee3] .el-dialog__header .el-dialog__close {
  margin-top: 2px;
  font-size: 20px;
  color: #6e777f;
  font-weight: 600;
}
[data-v-193f5ee3] .el-dialog__footer {
  border-top: 1px solid #eff0f2;
}