.el-select[data-v-09199c97] {
  width: 344px;
  height: 40px;
}
.el-select[data-v-09199c97]  input {
  background: #f8f9fa;
  border-color: #f5f5f5;
  border-radius: 12px;
  -webkit-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
          box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
}
.el-select[data-v-09199c97]  input:hover, .el-select[data-v-09199c97]  input:focus {
  border-color: #ccc;
}
.el-select[data-v-09199c97]  .el-input.is-focus input {
  border-color: #ccc;
}