.navbar[data-v-80f0e63c] {
  height: 50px;
  overflow: hidden;
  position: relative;
  background: #fff;
  -webkit-box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
          box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
}
.navbar .hamburger-container[data-v-80f0e63c] {
  line-height: 46px;
  height: 100%;
  float: left;
  cursor: pointer;
  -webkit-transition: background 0.3s;
  transition: background 0.3s;
  -webkit-tap-highlight-color: transparent;
}
.navbar .hamburger-container[data-v-80f0e63c]:hover {
  background: rgba(0, 0, 0, 0.025);
}
.navbar .breadcrumb-container[data-v-80f0e63c] {
  float: left;
}
.navbar .right-menu[data-v-80f0e63c] {
  float: right;
  height: 100%;
  line-height: 50px;
}
.navbar .right-menu[data-v-80f0e63c]:focus {
  outline: none;
}
.navbar .right-menu .right-menu-item[data-v-80f0e63c] {
  display: inline-block;
  padding: 0 8px;
  height: 100%;
  font-size: 18px;
  color: #5a5e66;
  vertical-align: text-bottom;
}
.navbar .right-menu .right-menu-item.hover-effect[data-v-80f0e63c] {
  cursor: pointer;
  -webkit-transition: background 0.3s;
  transition: background 0.3s;
}
.navbar .right-menu .right-menu-item.hover-effect[data-v-80f0e63c]:hover {
  background: rgba(0, 0, 0, 0.025);
}
.navbar .right-menu .avatar-container[data-v-80f0e63c] {
  margin-right: 30px;
}
.navbar .right-menu .avatar-container .avatar-wrapper[data-v-80f0e63c] {
  margin-top: 5px;
  position: relative;
}
.navbar .right-menu .avatar-container .avatar-wrapper .user-avatar[data-v-80f0e63c] {
  cursor: pointer;
  width: 40px;
  height: 40px;
  border-radius: 10px;
}
.navbar .right-menu .avatar-container .avatar-wrapper .el-icon-caret-bottom[data-v-80f0e63c] {
  cursor: pointer;
  position: absolute;
  right: -20px;
  top: 25px;
  font-size: 12px;
}