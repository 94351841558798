.tip[data-v-da6497f0] {
  color: #a7aaac;
  line-height: 1.5;
  margin-top: 8px;
}
.uploaded[data-v-da6497f0] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}