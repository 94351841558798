[data-v-20c093fd] .el-menu--collapse .submenu-title-noDropdown span,[data-v-20c093fd] .el-menu--collapse .el-submenu__title span {
  width: 0;
  overflow: hidden;
}
[data-v-20c093fd] .el-menu--collapse .submenu-title-noDropdown .menu-icon,[data-v-20c093fd] .el-menu--collapse .el-submenu__title .menu-icon {
  margin: 0 !important;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
[data-v-20c093fd] .el-submenu__icon-arrow {
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
  font-weight: 700;
  color: #9799A4;
}
[data-v-20c093fd] .el-submenu.is-opened > .el-submenu__title .el-submenu__icon-arrow {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
  color: #000;
}