.el-input[data-v-7802d402],
.el-textarea[data-v-7802d402] {
  width: 344px;
}
.el-input.el-input[data-v-7802d402],
.el-textarea.el-input[data-v-7802d402] {
  height: 40px;
}
.el-input[data-v-7802d402]  input,
.el-input[data-v-7802d402]  textarea,
.el-textarea[data-v-7802d402]  input,
.el-textarea[data-v-7802d402]  textarea {
  background: #f8f9fa;
  border-color: #f5f5f5;
  border-radius: 12px;
  -webkit-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
          box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
}
.el-input[data-v-7802d402]  input:hover, .el-input[data-v-7802d402]  input:focus,
.el-input[data-v-7802d402]  textarea:hover,
.el-input[data-v-7802d402]  textarea:focus,
.el-textarea[data-v-7802d402]  input:hover,
.el-textarea[data-v-7802d402]  input:focus,
.el-textarea[data-v-7802d402]  textarea:hover,
.el-textarea[data-v-7802d402]  textarea:focus {
  border-color: #ccc;
}
.el-input[data-v-7802d402]  .el-input__count,
.el-textarea[data-v-7802d402]  .el-input__count {
  background: transparent;
}
.el-input[data-v-7802d402]  .el-input__count .el-input__count-inner,
.el-textarea[data-v-7802d402]  .el-input__count .el-input__count-inner {
  background: transparent;
}
.el-input[data-v-7802d402]  textarea + .el-input__count,
.el-textarea[data-v-7802d402]  textarea + .el-input__count {
  line-height: 2;
}