#app {
  min-width: 1200px;
}

/* Global */
.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.flex.inline {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}
.flex.center {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.flex.right {
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}
.flex.between {
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.flex.middle {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.flex.stretch {
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
}
.flex.column {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.flex.average > * {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
.flex-1 {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
.hidden {
  overflow: hidden;
}
.text-center {
  text-align: center;
}
.ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.clearfix:after {
  content: " ";
  visibility: hidden;
  display: block;
  font-size: 0;
  clear: both;
  height: 0;
}
.el-button--text.no-padding {
  padding-top: 0;
  padding-bottom: 0;
}
.el-button--text.black.black {
  color: #222;
}
.el-button--text.black.black:hover, .el-button--text.black.black:focus, .el-button--text.black.black:visited {
  color: #666;
}
.el-button--text.black.black:active {
  color: #000;
}
.el-avatar > img {
  display: block;
  width: 100%;
  height: 100%;
}
.el-tooltip__popper.is-light.popper {
  border: 0;
  -webkit-box-shadow: 0px 4px 30px 0px rgba(71, 92, 110, 0.1);
          box-shadow: 0px 4px 30px 0px rgba(71, 92, 110, 0.1);
  padding: 6px 7px;
}
.el-tooltip__popper.is-light.popper .popper__arrow {
  border: 0;
}
.el-button.el-button--primary,
.el-button.el-button--info,
.el-button.el-button--default,
.el-button.el-button--danger {
  border-radius: 12px;
}
.el-checkbox .el-checkbox__inner {
  border-radius: 4px;
}
.el-checkbox .el-checkbox__label {
  color: #323232 !important;
}
.el-button svg.svg-icon {
  width: 1.1em;
}
.el-form-item.is-error .el-input__inner,
.el-form-item.is-error .el-input__inner:focus,
.el-form-item.is-error .el-textarea__inner,
.el-form-item.is-error .el-textarea__inner:focus,
.el-form-item.is-error .upload {
  border-color: #ff4949 !important;
}
.el-table .cell:empty::before,
.el-table .cell > span:empty::before {
  content: "-";
}
.el-table .is-leaf .cell:empty:before,
.el-table .is-leaf .cell > span:empty::before {
  content: "-";
}
.el-scrollbar .scrollbar-wrapper {
  overflow-x: hidden !important;
}
.el-tabs__item:focus.is-active.is-focus:not(:active) {
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
}
.search_user .el-input__inner {
  border-right: 0 !important;
}
.search_user .el-input__inner:focus {
  border-right: 1px solid #2969e3 !important;
}
.search_user .el-input-group__append {
  border-left: 0 !important;
  background-color: #fff !important;
}